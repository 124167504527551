import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BarVerticalComponent, calculateViewDimensions, LegendPosition, ScaleType } from '@swimlane/ngx-charts';

@Component({
    selector: 'cs-custom-bar-vertical',
    templateUrl: './custom-bar-vertical.component.html',
    styleUrls: ['./custom-bar-vertical.component.scss'],
})
export class CustomBarVerticalComponent extends BarVerticalComponent implements OnInit {
    @Input() legend = false;
    @Input() legendTitle = 'Legend';
    @Input() legendPosition = LegendPosition.Right;
    @Input() legendColumns: number;
    @Input() xAxis: boolean;
    @Input() yAxis: boolean;
    @Input() showXAxisLabel: boolean;
    @Input() showYAxisLabel: boolean;
    @Input() xAxisLabel;
    @Input() yAxisLabel;
    @Input() tooltipDisabled = false;
    @Input() gradient: boolean;
    @Input() showGridLines = true;
    @Input() activeEntries: any[] = [];
    @Input() schemeType = ScaleType.Ordinal;
    @Input() trimXAxisTicks = true;
    @Input() trimYAxisTicks = true;
    @Input() rotateXAxisTicks = true;
    @Input() maxXAxisTickLength = 16;
    @Input() maxYAxisTickLength = 16;
    @Input() xAxisTickFormatting: any;
    @Input() yAxisTickFormatting: any;
    @Input() xAxisTicks: any[];
    @Input() yAxisTicks: any[];
    @Input() barPadding = 8;
    @Input() roundDomains = false;
    @Input() roundEdges = true;
    @Input() yScaleMax: number;
    @Input() yScaleMin: number;
    @Input() showDataLabel = false;
    @Input() dataLabelFormatting: any;
    @Input() noBarWhenZero = true;

    @Output() activate: EventEmitter<any> = new EventEmitter();
    @Output() deactivate: EventEmitter<any> = new EventEmitter();
    @Output() select: EventEmitter<any> = new EventEmitter();

    @ContentChild('tooltipTemplate') tooltipTemplate: TemplateRef<any>;

    ngOnInit(): void {}

    update() {
        super.update();
        this.dims = calculateViewDimensions({
            width: this.width,
            height: this.height,
            margins: this.margin,
            showXAxis: this.xAxis,
            showYAxis: this.yAxis,
            xAxisHeight: this.xAxisHeight,
            yAxisWidth: this.yAxisWidth,
            showXLabel: this.showXAxisLabel,
            showYLabel: this.showYAxisLabel,
            showLegend: this.legend,
            legendType: this.schemeType,
            legendPosition: this.legendPosition,
            columns: this.getLegendOptions().columns,
        });
    }

    getLegendOptions() {
        const opts = {
            scaleType: this.schemeType,
            colors: undefined,
            domain: [],
            title: undefined,
            columns: this.legendColumns,
            position: this.legendPosition,
        };
        if (opts.scaleType === 'ordinal') {
            opts.domain = this.xDomain;
            opts.colors = this.colors;
            opts.title = this.legendTitle;
        } else {
            opts.domain = this.yDomain;
            opts.colors = this.colors.scale;
        }
        return opts;
    }
}
