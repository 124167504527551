import { Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CsOverlay } from '../../../core/ui/overlay/csOverlay';
import { SearchService } from './search.service';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { MapConfigState } from '../../map/state/map-config.state';
import { filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import {
    ChooseSearch,
    ClearSearchResultFeatures,
    LoadSearches,
    ResetSearchPagingOptions
} from '../state/search.actions';
import { SearchState } from '../state/search.state';
import { Observable, Subject } from 'rxjs';
import { SearchListEntry, SearchParamModel } from '../state/search.model';
import { CsUtil } from '../../shared/csUtil';
import { ChooseSearchDialogComponent } from '../choose/choose-search-dialog.component';
import { SearchCriteriaDialogComponent } from '../criteria/search-criteria-dialog.component';
import { SearchResultsOverlayRef } from '../results/search-results.overlay-ref';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { SearchResultsComponent } from '../results/search-results.component';
import { RouterNavigation } from '@ngxs/router-plugin';
import { SEARCH_RESULTS_DATA } from '../results/search-results.symbols';

@Injectable({
    providedIn: 'root'
})
export class SearchDisplayService {
    previousSearchAvailable$: Observable<boolean>;

    @Select(SearchState.currentSearch) private currentSearch$: Observable<SearchListEntry>;
    @Select(SearchState.searches) private searches$: Observable<SearchListEntry[]>;
    @Select(SearchState.currentParams) private currentParams$: Observable<(any) => SearchParamModel>;
    @Select(SearchState.previousSearchAvailable) private _previousAvailable$: Observable<(string) => boolean>;

    private id: string = CsUtil.uid();

    constructor(
        private matDialog: MatDialog,
        private overlay: CsOverlay,
        private searchService: SearchService,
        private injector: Injector,
        private store: Store,
        private actions$: Actions
    ) {
        this.previousSearchAvailable$ = this._previousAvailable$.pipe(
            map(fn => fn(this.id))
        );
    }

    showChooseDialog(finishedSignal$: Subject<void>) {
        // reset search
        this.closeResults();
        this.searchService.clearSearchParams();

        const ref = this.matDialog.open(ChooseSearchDialogComponent, {
            disableClose: true,
            panelClass: 'cs-toolbar-dialog',
            autoFocus: false,
            data: {
                id: this.id,
                reset: true
            }
        }).afterClosed().pipe(
            take(1)
        ).subscribe((result) => {
            if (result === false) {
                finishedSignal$.next();
            }
        });

        this.actions$.pipe(
            ofActionSuccessful(ChooseSearch),
            take(1)
        ).subscribe(() => this.showCriteriaDialog(finishedSignal$));

        return ref;
    }

    showCriteriaDialog(finishedSignal$: Subject<void>) {
        this.hideResults();
        // this.currentSearch$.pipe(first());
        const searchDialogRef = this.matDialog.open(SearchCriteriaDialogComponent, {
            data: {
                id: this.id,
                definition$: this.currentSearch$
            },
            disableClose: true,
            panelClass: 'cs-toolbar-dialog',
            width: 'auto'
        });
        const backSub = searchDialogRef.componentInstance.back.subscribe(() => {
            searchDialogRef.close();
            this.showChooseDialog(finishedSignal$);
        });
        searchDialogRef.afterClosed().subscribe((result) => {
            backSub.unsubscribe();
            if (result) {
                const searchOptions = {
                    id: this.id,
                    params: result.params,
                    searchName: result.name
                };
                this.showResults(searchOptions, finishedSignal$);
            }
        });
    }

    runPreviousSearch(finishedSignal$: Subject<void>) {
        this.currentSearch$.pipe(
            take(1),
            withLatestFrom(this.currentSearch$)
        ).subscribe(([ params, search ]) => {
            const options = {
                id: this.id,
                params,
                searchName: search.name
            };
            this.showResults(options, finishedSignal$);
        });
    }

    clearSearchResults() {
        setTimeout(() => this.store.dispatch(new ClearSearchResultFeatures({ displayLayer: 'searchFeatures' })), 0);
    }

    private showResults(searchOptions: any, finishedSignal$: Subject<void>) {
        const overlayRef = this.overlay.create({
            hasBackdrop: false,
            panelClass: 'cs-floating-panel',
            positionStrategy: this.overlay.position().global().centerHorizontally().top('100px')
        });
        const searchResultsRef = new SearchResultsOverlayRef(overlayRef);
        const injector = this.createInjector(searchOptions, searchResultsRef);
        const portal = new ComponentPortal(SearchResultsComponent, null, injector);
        const resultsRef = overlayRef.attach<SearchResultsComponent>(portal);
        resultsRef.instance.backToSearch.subscribe(() => {
            resultsRef.instance.close();
            this.store.dispatch(new ResetSearchPagingOptions({ id: this.id }));
            this.showCriteriaDialog(finishedSignal$);
        });
        resultsRef.instance.closeResults.subscribe(() => {
            resultsRef.instance.close();
            resultsRef.destroy();
            overlayRef.dispose();
            finishedSignal$.next()
        });

        this.actions$.pipe(ofActionSuccessful(RouterNavigation)).subscribe(() => {
            resultsRef.instance.close();
            finishedSignal$.next();
        });
    }

    private createInjector(searchOptions, overlayRef): PortalInjector {
        const tokens = new WeakMap();
        tokens.set(SearchResultsOverlayRef, overlayRef);
        tokens.set(SEARCH_RESULTS_DATA, searchOptions);
        return new PortalInjector(this.injector, tokens);
    }

    private hideResults() {
        /* noop */
    }

    private closeResults() {
        /* noop */
    }

}
