import { Injectable, Injector } from '@angular/core';
import { MAP_COMMAND_LIST } from './map-command-list.constant';
import { MapCommand } from './map-command.class';

@Injectable({ providedIn: 'root' })
export class MapCommandLocatorService {

    constructor(
        private injector: Injector
    ) { }

    create<T extends MapCommand>(name: string, attributes?: any): T {
        const commandLocator = MAP_COMMAND_LIST.find(c => c.name === name);
        const command: T = <T>this.injector.get(commandLocator.token);
        if (attributes) {
            command.setAttributes(attributes);
        }
        return command;
    }

}
