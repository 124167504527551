<div class='forgot-password-page' fxFill fxLayout='column' fxLayoutAlign='center center'>
    <ng-container *ngIf='(requestState$|async) == "complete"; then successTemplate; else formTemplate'></ng-container>
</div>

<ng-template #formTemplate>
    <mat-card class='forgot-password-card mat-elevation-z2'>
        <mat-toolbar color='primary' fxLayout='row' fxLayoutAlign='start center'>
            <h2 class='mat-toolbar-tools'>Password Reset</h2>
        </mat-toolbar>
        <mat-card-content cdkTrapFocus>
            <form name='forgotPasswordForm' (ngSubmit)='beginPasswordReset()' [formGroup]='forgotPasswordForm' novalidate>
                <div>
                    <div fxLayout='column' cdkTrapFocus cdkTrapFocusAutoCapture='true'>

                        <mat-form-field appearance='standard' hideRequiredMarker='true'>
                            <mat-label>Username or Email:</mat-label>
                            <input matInput type='text'
                                   [formControlName]="'identifier'"
                                   placeholder='Username or Email'
                                   cdkFocusInitial />
                        </mat-form-field>
                    </div>

                </div>

                <div class='forgot-password-actions' fxLayout='column' fxLayoutAlign='space-between stretch'>

                    <button mat-raised-button color='primary' type='submit' [disabled]='!forgotPasswordForm.valid'>
                        Request Password Reset
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</ng-template>

<ng-template #successTemplate>
    <mat-card class='forgot-password-card mat-elevation-z2'>
        <mat-toolbar color='primary' fxLayout='row' fxLayoutAlign='start center'>
            <h2 class='mat-toolbar-tools'>Request submitted.</h2>
        </mat-toolbar>
        <mat-card-content>
            <div>
                Your request has been successfully submitted. If the information you submitted corresponds to an
                existing account, an email will be sent to the address we have for you with instructions on how to
                reset your password.
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>
