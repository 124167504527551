import { Component, Input } from '@angular/core';
import { RestService } from '../../../../shared/services/rest.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { ReportDocumentField } from '../../../state/report.model';
import { DocumentLink } from '../../../../../elements/documentLink/document-link.model';
import { switchMap, take } from 'rxjs/operators';
import { StorageDirectUrl, StorageFile } from '../../../../../types';
import { ShowError } from '../../../../../core/state/notification/notification.actions';
import { EMPTY } from 'rxjs';
import { StorageService } from '../../../../shared/services/storage.service';
import { Store } from '@ngxs/store';

@Component({
    selector: 'cs-document-link-field',
    templateUrl: './document-link-field.component.html',
    styleUrls: ['./document-link-field.component.scss'],
})
export class DocumentLinkFieldComponent {
    @Input() rowData: any;
    @Input() fieldDefinition: ReportDocumentField;
    @Input() asLink = true;

    constructor(private logger: LoggerService, private store: Store, private rest: RestService, private storageService: StorageService) {}

    getFile() {
        const path = this.rowData[this.fieldDefinition.documentPath];
        this.rest
            .apiGet<StorageFile|StorageDirectUrl>('document', { path })
            .pipe(
                take(1),
                switchMap((storageObject) => {
                    if (storageObject['content'] !== undefined) {
                        return this.storageService.handleBlob(storageObject as StorageFile);
                    } else if (storageObject['url'] !== undefined) {
                        return this.storageService.handleUrl(storageObject as StorageDirectUrl);
                    } else {
                        this.store.dispatch(new ShowError('Invalid response when retrieving file.'));
                        return EMPTY;
                    }
                })
            )
            .subscribe();
    }
}
