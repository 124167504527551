import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { LoggerService } from '../../../../modules/shared/services/logger.service';
import { MapToolbarWidgetComponent } from '../../../../modules/map/toolbar/widget/map-toolbar-widget.component';
import { HotKeyService } from '../../../ui/hotkeys/hotKey.service';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { AuthState } from '../../state/authState.state';
import { takeUntil } from 'rxjs/operators';
import { ChangePasswordDialogComponent } from '../password/reset-password-dialog/change-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'cs-user-tools',
    templateUrl: './user-tools.component.html',
    styleUrls: [ './user-tools.component.scss' ]
})
export class UserToolsComponent {

    @Select(AuthState.isDefaultUser) public isDefaultUser$: Observable<boolean>;

    @Input() showUsername = false;

    username$ = new Subject<string>();

    private dispose$ = new Subject<boolean>();

    constructor(
        private store: Store,
        private dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.store.select(AuthState.user)
            .pipe(
                takeUntil(this.dispose$),
            ).subscribe(user => this.username$.next(user.username));
    }

    showResetForm() {
        this.dialog
            .open(
                ChangePasswordDialogComponent,
                { width: '420px' }
            );
    }
}
