<form #liveSearchForm="ngForm">
    <input name="liveSearch"
           [placeholder]="placeholder"
           [formControl]="liveSearchControl"
           [matAutocomplete]="auto">
    <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="formatSelected"
        (optionSelected)="onOptionSelected($event)">
        <mat-option *ngFor="let item of searchResults$ | async"
                    class="live-search-result"
                    [value]="item">
            <span class="live-search-result-row"
                  *ngFor="let resultRow of item.display">
                {{ resultRow }}
            </span>
        </mat-option>
    </mat-autocomplete>
</form>
