import { NgModule } from '@angular/core';
import { LiveSearchCustomUiComponent } from './custom-ui/live-search-custom-ui.component';
import { SharedModule } from '../../../shared/shared.module';
import { LIVE_SEARCH_COMMAND } from './live-search.token';
import { liveSearchFactory } from './live-search.factory';


@NgModule({
    declarations: [
        LiveSearchCustomUiComponent
    ],
    exports: [
        LiveSearchCustomUiComponent
    ],
    imports: [
        SharedModule,
    ],
    providers: [
        { provide: LIVE_SEARCH_COMMAND, useFactory: liveSearchFactory },
    ]
})
export class LiveSearchModule {}
