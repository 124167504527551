import { Injectable } from '@angular/core';
import { IsAuthenticated } from './isAuthenticated';
import { combineLatest, Observable } from 'rxjs';
import { concatMapTo, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthState } from '../state/authState.state';
import { BeginTokenRefresh, RequestUserInfo } from '../state/authState.actions';
import { Actions, Select, Store } from '@ngxs/store';
import { LoggerService } from '../../../modules/shared/services/logger.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { $D } from 'rxjs-debug';

@Injectable({
    providedIn: 'root'
})
export class PasswordAuthenticatedService implements IsAuthenticated {

    constructor(
        private logger: LoggerService,
        private jwtService: JwtHelperService,
        private store: Store,
        private actions$: Actions
    ) {}

    csIsAuthenticated$(): Observable<boolean> {
        const token$ = this.store.selectOnce(AuthState.token);
        const refreshToken$ = this.store.selectOnce(AuthState.refreshToken);
        const authenticationInProgress$ = this.store.selectOnce(AuthState.authenticationInProgress);
        const isStartingUp$ = this.store.selectOnce(AuthState.isStartingUp);

        return combineLatest([ token$, refreshToken$, authenticationInProgress$, isStartingUp$ ]).pipe(
            filter(([ , , inProgress, isStartingUp ]) => !inProgress || isStartingUp),
            tap(([ token, refreshToken ]) => {
                const expired = this.jwtService.isTokenExpired(token, 120);
                if (expired) {
                    if (refreshToken) {
                        setTimeout(() => this.store.dispatch(new BeginTokenRefresh()), 0);
                    }
                }
            }),
            map(([ token ]) => token),
            withLatestFrom(this.store.selectOnce(AuthState.validated)),
            take(1),
            tap(([ token, validated ]) => {
                if (token && !validated) {
                    setTimeout(() => this.store.dispatch(new RequestUserInfo()), 0);
                }
            }),
            concatMapTo(this.store.selectOnce(AuthState.validated)),
            take(1)
        );
    }
}
