import { Component, ElementRef, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MapCommand } from '../../command/map-command.class';
import { MapCommandHost } from '../../command/map-command-host.class';

@Component({
    selector: 'cs-map-context-menu-item',
    templateUrl: './map-context-menu-item.component.html',
    styleUrls: ['./map-context-menu-item.component.scss'],
})
export class MapContextMenuItemComponent extends MapCommandHost implements OnInit, OnChanges, OnDestroy {
    @Input() label: string;
    @Input() commandName: string;
    @Input() icon: string;
    @Input() attributes: { [key: string]: string };

    protected command: MapCommand;
    protected dispose$: Observable<void>;

    private disposeSubject = new Subject<void>();

    private element = inject(ElementRef);

    constructor() {
        super();
        this.dispose$ = this.disposeSubject.asObservable();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.commandName && changes.commandName?.currentValue) {
            this.command = this.setupCommand(changes.commandName.currentValue, changes?.attributes?.currentValue);
        }
    }

    ngOnInit(): void {}

    ngOnDestroy() {
        this.disposeSubject.next();
        this.disposeSubject.unsubscribe();
    }

    invoke() {
        this.command.invoke();
    }

    itemDeactivated() {
        this.command.deactivated$.next();
    }

    get customLabel$() {
        return this.command?.customLabel$;
    }
}
