<div class='context-menu' cdkMenu>
    <div class='context-menu-item-host' *ngFor='let menuItem of menuItems'>
        <button class='menu-item'
                (click)='menuItem.action()'
                cdkMenuItem>
            <mat-icon *ngIf='menuItem.icon' [svgIcon]='menuItem.icon'></mat-icon>
            <span>{{ menuItem.label }}</span>
        </button>
    </div>
</div>
