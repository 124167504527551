import { Injectable } from '@angular/core';
import { LoggerService } from '../../../modules/shared/services/logger.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Select, Store } from '@ngxs/store';
import { defer, EMPTY, from, Observable, of } from 'rxjs';
import { LoginMethodType } from '../state/authState.model';
import { AuthState } from '../state/authState.state';
import { catchError, concatMap, concatMapTo, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { OpenIdLoginSuccessful } from '../state/openIdState.actions';
import { OpenIdState } from '../state/openIdState.state';
import { IsAuthenticated } from './isAuthenticated';
import { RequestUserInfo } from '../state/authState.actions';

@Injectable({
    providedIn: 'root',
})
export class OpenIdAuthenticatedService implements IsAuthenticated {
    @Select(OpenIdState.initialized) private oidcInitialized$: Observable<boolean>;
    @Select(AuthState.activeMethod) private activeMethod$: Observable<LoginMethodType>;

    constructor(private logger: LoggerService, private oauthService: OAuthService, private store: Store) {
    }

    public csIsAuthenticated$() {
        const isActiveMethodOidc$ = this.activeMethod$.pipe(map((m) => m === 'oidc'));
        const tryOidc$ = this.oidcInitialized$.pipe(
            filter((i) => i),
            concatMapTo(defer(() => this.oauthService.tryLogin())),
            concatMap(() => {
                if (this.oauthService.hasValidIdToken()) {
                    this.store.dispatch(new RequestUserInfo());
                    return of(true);
                }
                if (this.oauthService.getRefreshToken()) {
                    return from(this.oauthService.refreshToken());
                }
                return of(false);
            })
        );

        return isActiveMethodOidc$.pipe(
            concatMap((isActiveMethodOidc) => (isActiveMethodOidc ? tryOidc$ : of(false))),
            map((b) => !!b),
            catchError((err) => {
                this.logger.error(err);
                return of(false);
            }),
            take(1),
            tap((authenticated) => {
                if (authenticated && this.oauthService.hasValidAccessToken()) {
                    this.store.dispatch(new OpenIdLoginSuccessful());
                }
            })
        );
    }
}
