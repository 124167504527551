import { Injectable } from '@angular/core';
import { filter, mergeMap, take } from 'rxjs/operators';
import { LogoutDialogComponent } from './logout-dialog.component';
import { BeginLogout } from '../../state/authState.actions';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { MatDialog } from '@angular/material/dialog';
import { AuthState } from '../../state/authState.state';
import { AuthenticatedUser } from '../../state/authState.model';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LogoutService {

    @Select(AuthState.user) private user$: Observable<AuthenticatedUser>;

    constructor(
        private store: Store,
        private dialog: MatDialog
    ) { }

    doLogout() {
        this.user$
            .pipe(
                filter((user) => !!(user && user.username)),
                take(1),
                mergeMap(() => this.dialog.open(LogoutDialogComponent).afterClosed()),
                filter((b) => b),
                mergeMap(() => this.store.dispatch(new BeginLogout(true)))
            )
            .subscribe(() => {
                // explicitly do NOT keep query params on logout
                this.store.dispatch(new Navigate([ '/auth/login' ], null, { queryParamsHandling: '' }));
            });
    }
}
