import { Injectable } from '@angular/core';
import { race, zip } from 'rxjs';
import { ActiveMapState } from '../../map/state/active-map.state';
import { filter, take } from 'rxjs/operators';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { MapCreated } from '../../map/state/active-map.actions';
import { Navigate } from '@ngxs/router-plugin';
import { QueryService } from '../../map/services/query.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ZoomService {
    constructor(
        private store: Store,
        private queryService: QueryService,
        private actions$: Actions,
        private router: Router
    ) {}

    zoom(layer, field, key) {
        // either the map needs to be initialized or it is ready
        const mapReady$ = race(
            this.store.select(ActiveMapState.created).pipe(filter((b) => b)),
            this.actions$.pipe(ofActionSuccessful(MapCreated))
        );

        const navigate$ = this.store.dispatch(new Navigate(['/map'])).pipe(take(1));

        // when map is ready and we have navigated to it, then select/zoom
        zip(mapReady$, navigate$).subscribe(() => {
            this.queryService.zoomTo(layer, field, key);
        });
    }
}
