import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { NestedTreeControl } from '@angular/cdk/tree';
import { LegendNode, MsClassNode, MsGroupNode, MsLayerNode } from '../state/map-config.model';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { RedlineLayerConfiguration, RedlineLegendNode } from '../../../components/redline/state/redline.model';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { AppConfigState } from '../../../core/state/appConfig/app-config.state';
import { Observable, of, Subject } from 'rxjs';
import { LegendIconDimensions } from '../../../core/state/appConfig/app-config.model';
import { MapConfigState } from '../state/map-config.state';
import { RedlineState } from '../../../components/redline/state/redline.state';
import { ActiveMapState } from '../state/active-map.state';
import { LoggerService } from '../../shared/services/logger.service';
import { RestService } from '../../shared/services/rest.service';
import { MapConfigService } from '../services/mapConfig.service';
import { RedlineConfigService } from '../../../components/redline/services/redline-config.service';
import { DeviceService } from '../../shared/services/device.service';
import { MapService } from '../services/map.service';
import {
    concatMap,
    distinctUntilChanged,
    filter,
    map,
    mergeMap,
    switchMap,
    switchMapTo, takeUntil,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { RedlineVisibilityChanged } from '../../../components/redline/state/redline.actions';
import { SetNodeExpandedState } from '../state/map-config.actions';
import { CsUtil } from '../../shared/csUtil';

@Component({
    selector: 'cs-map-legend-container',
    templateUrl: './map-legend-container.component.html',
    styleUrls: [ './map-legend-container.component.scss' ],
    providers: [ { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'check' } } ]
})
export class MapLegendContainerComponent implements OnInit, OnDestroy {

    public treeControl: NestedTreeControl<LegendNode>;
    public dataSource: MatTreeNestedDataSource<LegendNode>;
    public redlineTreeControl: NestedTreeControl<RedlineLegendNode>;
    public redlineDataSource: MatTreeNestedDataSource<RedlineLegendNode>;

    @Select(AppConfigState.legendIcons) private legendIcons$: Observable<LegendIconDimensions>;
    @Select(MapConfigState.loaded) private mapConfigLoaded$: Observable<boolean>;
    @Select(MapConfigState.mapName) private mapName$: Observable<string>;
    @Select(MapConfigState.visibleRoot) private root$: Observable<MsGroupNode>;
    @Select(RedlineState.loaded) private redlineLoaded$: Observable<boolean>;
    @Select(ActiveMapState.scale) private currentScale$: Observable<number>;

    private children$: Observable<LegendNode[]>;
    private dispose$ = new Subject<void>();

    /**
     * @param elementRef {ElementRef}
     * @param logger {LoggerService}
     * @param restService {RestService}
     * @param mapConfig {MapConfigService}
     * @param redlineConfig {RedlineConfigService}
     * @param deviceService {DeviceService}
     * @param mapService {MapService}
     * @param actions$ {Actions}
     * @param store {Store}
     */
    constructor(
        public elementRef: ElementRef,
        private logger: LoggerService,
        private restService: RestService,
        private mapConfig: MapConfigService,
        private redlineConfig: RedlineConfigService,
        private deviceService: DeviceService,
        private mapService: MapService,
        private actions$: Actions,
        private store: Store,
    ) {
        this.treeControl = new NestedTreeControl<LegendNode>(this.getChildren.bind(this));
        this.dataSource = new MatTreeNestedDataSource<LegendNode>();

        this.redlineTreeControl = new NestedTreeControl<RedlineLegendNode>(this.getRedlineChildren);
        this.redlineDataSource = new MatTreeNestedDataSource<RedlineLegendNode>();

        this.children$ = this.root$.pipe(
            map((root) => this.getChildren(root)),
        );
    }

    public ngOnInit() {
        this.mapConfigLoaded$
            .pipe(
                distinctUntilChanged(),
                filter((b) => b),
                mergeMap(() => this.legendIcons$),
                withLatestFrom(this.mapName$),
                concatMap(([legendIcons, mapName]) => {
                    const width = legendIcons.width;
                    const height = legendIcons.height;
                    return this.restService.apiGet(`map/icons/${mapName}/${width}/${height}`, null);
                }),
                tap((data: any) => {
                    if (data && data.css) {
                        const head = document.getElementsByTagName('head')[0];
                        const style = document.createElement('style');
                        style.appendChild(document.createTextNode(data.css));
                        head.appendChild(style);
                    }
                }),
                takeUntil(this.dispose$),
            ).subscribe();

        this.redlineLoaded$
            .pipe(
                distinctUntilChanged(isEqual),
                filter((b) => b),
                switchMapTo(this.currentScale$),
                switchMap((scale) => this.redlineConfig.getInRangeLayerList$(scale)),
                takeUntil(this.dispose$),
            )
            .subscribe((inRange) => {
                this.redlineDataSource.data = inRange;
            });

        this.actions$
            .pipe(
                ofActionSuccessful(RedlineVisibilityChanged),
                map(() => this.mapService.currentScale),
                switchMap((scale) => this.redlineConfig.getInRangeLayerList$(scale)),
                takeUntil(this.dispose$),
            )
            .subscribe((inRange) => {
                this.redlineDataSource.data = inRange;
            });

        this.children$.pipe(
            takeUntil(this.dispose$),
        ).subscribe((children) => {
            this.dataSource.data = children;
        });
    }

    ngOnDestroy() {
        this.dispose$.next();
    }

    public toggleNode(node) {
        const nodeType = MapLegendContainerComponent.getNodeType(node);
        this.store.dispatch(new SetNodeExpandedState(node.name, nodeType === 'group', !node.expand));
    }

    public getChildren(node: LegendNode): LegendNode[] {
        switch (MapLegendContainerComponent.getNodeType(node)) {
            case 'group':
                const groupNode = node as MsGroupNode;
                if (groupNode.expand) {
                    this.treeControl.expand(groupNode);
                } else {
                    this.treeControl.collapse(groupNode);
                }
                const groupChildren: Array<MsGroupNode | MsLayerNode> = [];
                groupChildren.push(...groupNode.layers, ...groupNode.groups);
                return groupChildren.sort(
                    (a: MsLayerNode | MsGroupNode, b: MsLayerNode | MsGroupNode) => a.legendOrder - b.legendOrder
                );
            case 'layer':
                const layerNode = node as MsLayerNode;
                if (layerNode.expand) {
                    this.treeControl.expand(layerNode);
                } else {
                    this.treeControl.collapse(layerNode);
                }
                return layerNode.classes.map((klass: MsClassNode) => ({
                    ...klass,
                    layer: layerNode.name,
                }));
            case 'class':
                return [];
        }
    }

    public redlineHasMultipleClasses(index: number, node: RedlineLegendNode): boolean {
        switch (MapLegendContainerComponent.getRedlineNodeType(node)) {
            case 'layer':
                const layerNode = node as RedlineLayerConfiguration;
                return Object.getOwnPropertyNames(layerNode.classes).length > 1;
            case 'class':
                return false;
        }
    }

    public redlineHasOneClass(index: number, node: RedlineLegendNode): boolean {
        switch (MapLegendContainerComponent.getRedlineNodeType(node)) {
            case 'layer':
                const layerNode = node as RedlineLayerConfiguration;
                return Object.getOwnPropertyNames(layerNode.classes).length === 1;
            case 'class':
                return false;
        }
    }

    public getRedlineChildren(node: RedlineLegendNode): Observable<RedlineLegendNode[]> {
        switch (MapLegendContainerComponent.getRedlineNodeType(node)) {
            case 'layer':
                const layerNode = node as RedlineLayerConfiguration;
                const classes: RedlineLegendNode[] = [];
                Object.getOwnPropertyNames(layerNode.classes).forEach((className) => {
                    const redlineClass = layerNode.classes[className];
                    redlineClass.name = className;
                    redlineClass.parent = layerNode;
                    classes.push(redlineClass);
                });
                return of(classes);
            case 'class':
                return of([]);
        }
    }

    public isGroup(index, node): boolean {
        return MapLegendContainerComponent.getNodeType(node) === 'group';
    }

    public isExpandableGroup(index, node): boolean {
        return (
            MapLegendContainerComponent.getNodeType(node) === 'group' &&
            (node.groups.length > 0 || node.layers.length > 0) &&
            node.expandable
        );
    }

    public isLayer(index, node): boolean {
        return MapLegendContainerComponent.getNodeType(node) === 'layer';
    }

    public isExpandableLayer(index, node): boolean {
        return MapLegendContainerComponent.getNodeType(node) === 'layer' && node.classes.length > 1 && node.expandable;
    }

    public isNonExpandableLayer(index, node): boolean {
        return MapLegendContainerComponent.getNodeType(node) === 'layer' && node.classes.length > 1 && !node.expandable;
    }

    public toggleLayer(node: MsLayerNode) {
        if (node.status) {
            this.mapConfig.hideLayer(node.name);
        } else {
            this.mapConfig.showLayer(node.name);
        }
    }

    public toggleGroup(node: MsGroupNode) {
        if (node.status) {
            this.mapConfig.hideGroup(node.name);
        } else {
            this.mapConfig.showGroup(node.name);
        }
    }

    public toggleRedlineLayer(node: RedlineLayerConfiguration) {
        if (node.status) {
            this.redlineConfig.hideLayer(node.name);
        } else {
            this.redlineConfig.showLayer(node.name);
        }
    }

    public trackByFn(index: number, node: LegendNode) {
        return CsUtil.hash(JSON.stringify(node));
    }

    private static getNodeType(node: LegendNode) {
        if ((node as MsGroupNode).layers && (node as MsGroupNode).groups) {
            return 'group';
        } else if ((node as MsLayerNode).classes) {
            return 'layer';
        } else {
            return 'class';
        }
    }

    private static getRedlineNodeType(node: RedlineLegendNode) {
        if ((node as RedlineLayerConfiguration).classes) {
            return 'layer';
        } else {
            return 'class';
        }
    }

    private setExpansion(node: LegendNode) {
        if (node.hasOwnProperty('expand')) {
            if (node['expand']) {
                this.treeControl.expand(node);
            } else {
                this.treeControl.collapse(node);
            }

            const nodes = this.treeControl.getDescendants(node);
            for (let i = 0; i < nodes.length; i++) {
                this.setExpansion(nodes[i]);
            }
        }
    }
}
