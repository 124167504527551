<ng-container *ngIf='formDefinition$ | async as formDefinition'>
    <form [formGroup]='dataForm' *ngIf='initialized$ | async'>
        <div class='cs-data-form-field-container' fxLayout='column'>
            <ng-container *ngFor='let field of formDefinition.fields; trackBy: trackByDbNameFn'>

                <ng-container [ngSwitch]='field.uiType'>

                    <mat-form-field *ngSwitchCase="'text'">
                        <mat-label>{{field.displayName}}</mat-label>
                        <input matInput type='text'
                               [formControlName]='field.fieldName'
                               [placeholder]='field.displayName'
                               [required]='field.required'
                               [name]='field.fieldName' />

                        <mat-error *ngIf="dataForm.get(field.fieldName).hasError('pattern')">
                            {{ field.validationMessage }}
                        </mat-error>
                        <mat-error *ngIf="dataForm.get(field.fieldName).hasError('required')">
                            {{ field.displayName }} is required.
                        </mat-error>

                    </mat-form-field>

                    <mat-form-field *ngSwitchCase="'textarea'">
                        <mat-label>{{field.displayName}}</mat-label>
                        <textarea matInput cdkTextareaAutosize
                                  [formControlName]='field.fieldName'
                                  [placeholder]='field.displayName'
                                  [required]='field.required'
                                  [name]='field.fieldName'></textarea>

                        <mat-error *ngIf="dataForm.get(field.fieldName).hasError('pattern')">
                            {{ field.validationMessage }}
                        </mat-error>
                        <mat-error *ngIf="dataForm.get(field.fieldName).hasError('required')">
                            {{ field.displayName }} is required.
                        </mat-error>

                    </mat-form-field>

                    <ng-container *ngSwitchCase="'dropdown'">
                        <ng-container *ngTemplateOutlet='dropdownTemplate; context: {field, dataForm}'></ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="'dropdown_multi'">
                        <mat-form-field *ngIf='!field.parameter'>
                            <mat-label>{{field.displayName}}</mat-label>
                            <mat-select [formControlName]='field.fieldName'
                                        [placeholder]='field.displayName'
                                        [required]='field.required'
                                        multiple
                                        aria-label='{{field.displayName}}'>

                                <mat-option *ngFor='let opt of field.dropdownItems'
                                            [value]='opt.value'>
                                    {{ opt.text }}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="dataForm.get(field.fieldName).hasError('required')">
                                {{ field.displayName }} is required.
                            </mat-error>

                        </mat-form-field>


                        <mat-form-field *ngIf='!!field.parameter'>
                            <mat-label>{{field.displayName}}</mat-label>
                            <mat-select [formControlName]='field.fieldName'
                                        [placeholder]='field.displayName'
                                        [required]='field.required'
                                        multiple
                                        aria-label='{{field.displayName}}'>

                                <mat-option *ngFor='let opt of getDropDownOptions$(field) | async'
                                            [value]='opt.value'>
                                    {{ opt.text }}
                                </mat-option>
                            </mat-select>

                            <mat-error *ngIf="dataForm.get(field.fieldName).hasError('required')">
                                {{ field.displayName }} is required.
                            </mat-error>

                        </mat-form-field>

                    </ng-container>

                    <mat-form-field *ngSwitchCase="'date'">
                        <mat-label>{{field.displayName}}</mat-label>
                        <input matInput
                               [formControlName]='field.fieldName'
                               [placeholder]='field.displayName'
                               [required]='field.required'
                               [matDatepicker]='dataFormDatePicker'>
                        <mat-datepicker-toggle matSuffix [for]='dataFormDatePicker'></mat-datepicker-toggle>
                        <mat-datepicker #dataFormDatePicker></mat-datepicker>

                        <mat-error *ngIf='isDateInvalid(field.fieldName)'>
                            Invalid date format.
                        </mat-error>
                        <mat-error *ngIf='isDateRequiredAndMissing(field.fieldName)'>
                            {{ field.displayName }} is required.
                        </mat-error>

                    </mat-form-field>

                    <mat-form-field *ngSwitchCase="'barcode'">
                        <mat-label>{{field.displayName}}</mat-label>
                        <input matInput type='text' #barcodeTarget
                               [formControlName]='field.fieldName'
                               [placeholder]='field.displayName'
                               [required]='field.required'
                               [name]='field.fieldName' />
                        <cs-barcode-scanner-button matSuffix
                                                   [target]='dataForm.controls[field.fieldName]'></cs-barcode-scanner-button>

                        <mat-error *ngIf="dataForm.get(field.fieldName).hasError('pattern')">
                            {{ field.validationMessage }}
                        </mat-error>
                        <mat-error *ngIf="dataForm.get(field.fieldName).hasError('required')">
                            {{ field.displayName }} is required.
                        </mat-error>

                    </mat-form-field>

                    <ng-container *ngSwitchCase="'hidden'">
                        <input type='hidden' [formControlName]='field.fieldName' />
                    </ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngIf='saveError$|async as error'>
                <div class='mat-error'>{{ error.message }}</div>
            </ng-container>
        </div>
    </form>
</ng-container>

<ng-template #dropdownTemplate let-field="field" let-form="dataForm">
    <ng-container [formGroup]='form'>
        <mat-form-field *ngIf='!field.parameter'>
            <mat-label>{{field.displayName}}</mat-label>
            <mat-select [formControlName]='field.fieldName'
                        [placeholder]='field.displayName'
                        [required]='field.required'
                        aria-label='{{field.displayName}}'>

                <mat-option [value]='undefined'>-- Select --</mat-option>
                <mat-option *ngFor='let opt of field.dropdownItems'
                            [value]='opt.value'>
                    {{ opt.text }}
                </mat-option>
            </mat-select>

            <mat-error *ngIf="dataForm.get(field.fieldName).hasError('required')">
                {{ field.displayName }} is required.
            </mat-error>

        </mat-form-field>


        <mat-form-field *ngIf='!!field.parameter'>
            <mat-label>{{field.displayName}}</mat-label>
            <mat-select [formControlName]='field.fieldName'
                        [placeholder]='field.displayName'
                        [required]='field.required'
                        aria-label='{{field.displayName}}'>
                <mat-option [value]='undefined'>-- Select --</mat-option>
                <mat-option *ngFor='let opt of getDropDownOptions$(field) | async'
                            [value]='opt.value'>
                    {{ opt.text }}
                </mat-option>
            </mat-select>

            <mat-error *ngIf="dataForm.get(field.fieldName).hasError('required')">
                {{ field.displayName }} is required.
            </mat-error>

        </mat-form-field>
    </ng-container>
</ng-template>
