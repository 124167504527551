import { Injectable } from '@angular/core';
import { LoggerService } from '../../../modules/shared/services/logger.service';
import { RestService } from '../../../modules/shared/services/rest.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    ConfirmAccountPayload,
    LoginResponse,
    PasswordComplexity,
    ResetPasswordPayload
} from '../state/authState.model';
import { ResetPassword } from '../state/authState.actions';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {

    constructor(
        private logger: LoggerService,
        private rest: RestService
    ) { }

    verifyResetKey$(resetKey: string): Observable<boolean> {
        const postData = { resetKey };
        return this.rest.apiPost<boolean>('auth/password/forgot/check', postData);
    }

    resetForgottenPassword$(resetKey: string, password: string): Observable<LoginResponse> {
        const postData = {resetKey, password};
        return this.rest.apiPost<LoginResponse>('auth/password/forgot/reset', postData);
    }

    resetPassword$(data: ResetPasswordPayload): Observable<LoginResponse> {
        return this.rest.apiPost<LoginResponse>('auth/password/reset', data);
    }

    complexity$(password: string): Observable<PasswordComplexity> {
        return this.rest.apiPost<PasswordComplexity>('auth/password/complexity', { candidate: password });
    }

    verifyConfirmationKey$(confirmationKey: string): Observable<boolean> {
        return this.rest.apiPost<boolean>('auth/confirm/check', { confirmationKey });
    }

    confirmAccount$(data: ConfirmAccountPayload): Observable<LoginResponse> {
        return this.rest.apiPost<LoginResponse>('auth/confirm', data);
    }
}

