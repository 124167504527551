<div class='complexity-container'>
    <div class='complexity-bar-container' [ngSwitch]='score'>
        <div *ngSwitchCase='0' class='complexity-bar score-0'></div>
        <div *ngSwitchCase='1' class='complexity-bar score-1'></div>
        <div *ngSwitchCase='2' class='complexity-bar score-2'></div>
        <div *ngSwitchCase='3' class='complexity-bar score-3'></div>
        <div *ngSwitchCase='4' class='complexity-bar score-4'></div>
    </div>
    <div class='complexity-labels' fxLayout='row' fxLayoutAlign="space-between stretch">
        <div>Weak</div>
        <div>Strong</div>
    </div>
    <div *ngIf='warning !== ""'>
        <span>Warning: {{ warning }}</span>
    </div>

    <ng-container *ngIf='suggestions.length > 0'>
        <div *ngFor='let suggestion of suggestions'>{{suggestion}}</div>
    </ng-container>
</div>
