<ngx-charts-chart
        [view]="[width, height]"
        [showLegend]="legend"
        [legendOptions]="legendOptions"
        [activeEntries]="activeEntries"
        [animations]="animations"
        (legendLabelClick)="onClick($event)"
        (legendLabelActivate)="onActivate($event, true)"
        (legendLabelDeactivate)="onDeactivate($event, true)"
>
    <svg:g [attr.transform]="transform" class="bar-chart chart">
        <svg:g
                ngx-charts-x-axis
                *ngIf="xAxis"
                [xScale]="xScale"
                [dims]="dims"
                [showLabel]="showXAxisLabel"
                [labelText]="xAxisLabel"
                [trimTicks]="trimXAxisTicks"
                [rotateTicks]="rotateXAxisTicks"
                [maxTickLength]="maxXAxisTickLength"
                [tickFormatting]="xAxisTickFormatting"
                [ticks]="xAxisTicks"
                [xAxisOffset]="dataLabelMaxHeight.negative"
                (dimensionsChanged)="updateXAxisHeight($event)"
        ></svg:g>
        <svg:g
                ngx-charts-y-axis
                *ngIf="yAxis"
                [yScale]="yScale"
                [dims]="dims"
                [showGridLines]="showGridLines"
                [showLabel]="showYAxisLabel"
                [labelText]="yAxisLabel"
                [trimTicks]="trimYAxisTicks"
                [maxTickLength]="maxYAxisTickLength"
                [tickFormatting]="yAxisTickFormatting"
                [ticks]="yAxisTicks"
                (dimensionsChanged)="updateYAxisWidth($event)"
        ></svg:g>
        <svg:g
                ngx-charts-series-vertical
                [xScale]="xScale"
                [yScale]="yScale"
                [colors]="colors"
                [series]="results"
                [dims]="dims"
                [gradient]="gradient"
                [tooltipDisabled]="tooltipDisabled"
                [tooltipTemplate]="tooltipTemplate"
                [showDataLabel]="showDataLabel"
                [dataLabelFormatting]="dataLabelFormatting"
                [activeEntries]="activeEntries"
                [roundEdges]="roundEdges"
                [animations]="animations"
                [noBarWhenZero]="noBarWhenZero"
                (activate)="onActivate($event)"
                (deactivate)="onDeactivate($event)"
                (select)="onClick($event)"
                (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event)"
        ></svg:g>
    </svg:g>
</ngx-charts-chart>
