import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { InitForgotPasswordRequest } from '../../state/authState.actions';
import { AuthState } from '../../state/authState.state';
import { PasswordRequestState } from '../../state/authState.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'cs-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: [ './forgot-password.component.scss' ]
})
export class ForgotPasswordComponent implements OnInit {
    @Select(AuthState.forgotPasswordRequestState)
    requestState$: Observable<PasswordRequestState>;

    forgotPasswordForm: UntypedFormGroup;

    constructor(
        private store: Store,
        private fb: UntypedFormBuilder
    ) {
        this.forgotPasswordForm = fb.group({
            identifier: [ '', Validators.required ]
        });
    }

    ngOnInit(): void {
    }

    beginPasswordReset() {
        const identifier = this.forgotPasswordForm.get('identifier').value;
        this.store.dispatch(new InitForgotPasswordRequest(identifier));
    }
}
