import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoggerService } from '../../../modules/shared/services/logger.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from '../components/password/reset-password-dialog/change-password-dialog.component';
import { Store } from '@ngxs/store';
import { BeginForceResetPassword, EndForceResetPassword } from '../state/authState.actions';
import { AuthState } from '../state/authState.state';

@Injectable()
export class ForcePasswordChangeInterceptor implements HttpInterceptor {

    constructor(
        private logger: LoggerService,
        private dialog: MatDialog,
        private store: Store
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    const inProgress = this.store.selectSnapshot(AuthState.forcePasswordResetInProgress);
                    const needReset = (event.body?.metadata?.forceReset);
                    if (!inProgress && needReset) {
                        this.store.dispatch(new BeginForceResetPassword());
                        this.dialog
                            .open(
                                ChangePasswordDialogComponent,
                                {
                                    disableClose: true,
                                    width: '420px',
                                    data: {
                                        force: true,
                                        message: 'You must update your password before continuing.'
                                    }
                                }
                            ).afterClosed().subscribe(() => this.store.dispatch(new EndForceResetPassword()));
                    }
                }
            })
        );
    }
}
