<div class='context-menu' cdkMenu>
    <ng-container *ngFor='let item of visibleMenuItems$ | async'>
        <cs-map-context-menu-item
            class='context-menu-item-host'
            [commandName]='item.commandName'
            [label]='item.label'
            [icon]='item.icon'
            [attributes]='item.attributes'></cs-map-context-menu-item>
    </ng-container>
</div>
