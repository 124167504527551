import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { createCustomElement } from '@angular/elements';
import { FileDownloadComponent } from '../fileDownload/file-download.component';
import { DocumentLinkComponent } from './document-link.component';
import { SharedModule } from '../../modules/shared/shared.module';
import { DownloadService } from '../../modules/shared/services/download.service';

@NgModule({
    declarations: [ DocumentLinkComponent ],
    imports: [ SharedModule ],
    providers: [DownloadService]
})
export class DocumentLinkModule {
    constructor(private injector: Injector) {
        const el = createCustomElement(DocumentLinkComponent, {
            injector: this.injector,
        });
        customElements.define('cs-document-link', el);
    }
}
