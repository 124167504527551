import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { BufferService } from './buffer.service';
import { CREATE_BUFFER_COMMAND } from './create-buffer/create-buffer.token';
import { SELECT_WITHIN_BUFFER_COMMAND } from './select-within-buffer/select-within-buffer.token';
import { createBufferFactory } from './create-buffer/create-buffer.factory';
import { selectWithinBufferFactory } from './select-within-buffer/select-within-buffer.factory';
import { CLEAR_BUFFER_COMMAND } from './clear-buffer/clear-buffer.token';
import { clearBufferFactory } from './clear-buffer/clear-buffer.factory';
import { BufferDialogComponent } from './create-buffer/dialog/buffer-dialog.component';
import { ActiveToolModule } from '../../toolbar/active-tool/active-tool.module';


@NgModule({
    declarations: [
        BufferDialogComponent
    ],
    imports: [
        SharedModule,
    ],
    providers: [
        BufferService,
        { provide: CREATE_BUFFER_COMMAND, useFactory: createBufferFactory },
        { provide: SELECT_WITHIN_BUFFER_COMMAND, useFactory: selectWithinBufferFactory },
        { provide: CLEAR_BUFFER_COMMAND, useFactory: clearBufferFactory },
    ]
})
export class BufferModule {}
