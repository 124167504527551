import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LoggerService } from '../../../../shared/services/logger.service';
import { QrCodeService } from '../../../../shared/services/qr-code.service';

@Component({
    selector: 'cs-qr-code-field',
    templateUrl: './qr-code-field.component.html',
    styleUrls: ['./qr-code-field.component.scss'],
})
export class QrCodeFieldComponent implements OnInit, AfterViewInit {
    @Input() rowData: any;
    @Input() fieldDefinition: any;
    @Input() svgWidth = 30;

    constructor(protected logger: LoggerService, protected qrService: QrCodeService, protected el: ElementRef) {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        const value = this.rowData[this.fieldDefinition.name];
        this.qrService
            .generateQrCode$(value, this.svgWidth)
            .subscribe((svgQrCode: string) => (this.el.nativeElement.innerHTML = svgQrCode));
    }
}
