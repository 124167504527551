<button class='menu-item'
        *ngIf='(hasMenu$ | async)'
        (click)='invoke()'
        [csActiveTool]='commandName'
        (deactivated)='itemDeactivated()'
        [disabled]='shouldDisable$|async'
        [cdkMenuTriggerFor]='subMenu'
        cdkMenuItem>
    <mat-icon *ngIf='!!icon' [svgIcon]='icon'></mat-icon>
    <span>{{ (customLabel$ | async) ?? label }}</span>
</button>

<button class='menu-item'
        *ngIf='!(hasMenu$ | async)'
        (click)='invoke()'
        [csActiveTool]='commandName'
        (deactivated)='itemDeactivated()'
        [disabled]='shouldDisable$|async'
        cdkMenuItem>
    <mat-icon *ngIf='!!icon' [svgIcon]='icon'></mat-icon>
    <span>{{ (customLabel$ | async) ?? label }}</span>
</button>

<ng-template  #subMenu>
    <cs-sub-menu [menuItems]='widgetMenuItems$ | async'></cs-sub-menu>
</ng-template>
