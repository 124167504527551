import { NgModule } from '@angular/core';
import { MEASURE_COMMAND } from './measure.token';
import { measureFactory } from './measure.factory';
import { SharedModule } from '../../../shared/shared.module';
import { CsUiModule } from '../../../../core/ui/csUi.module';
import { MeasureDialogComponent } from './dialog/measure-dialog.component';

@NgModule({
    imports: [SharedModule, CsUiModule],
    providers: [{ provide: MEASURE_COMMAND, useFactory: measureFactory }],
    declarations: [MeasureDialogComponent],
})
export class MeasureModule {}
