<div class="legend-content">
    <!-- REDLINES -->
    <ng-container *ngIf="redlineDataSource.data.length > 0">
        <h2 class="mat-h3" *ngIf='dataSource.data.length > 0'>Redline Layers</h2>
        <mat-tree [dataSource]="redlineDataSource" [treeControl]="redlineTreeControl">
            <mat-nested-tree-node *matTreeNodeDef="let redlineNode; when: redlineHasMultipleClasses">
                <li><!-- this is an expandable layer node (has more than 1 class) -->
                    <div class="mat-tree-node legend-node layer-node expandable-node">
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="'toggle ' + redlineNode.label">
                            <mat-icon class="mat-icon-rtl-mirror"
                                      [svgIcon]="redlineTreeControl.isExpanded(redlineNode) ? 'expand_more' : 'chevron_right'">
                            </mat-icon>
                        </button>
                        <mat-checkbox [ngModel]="redlineNode.on"
                                      [disableRipple]="true"
                                      (ngModelChange)="toggleRedlineLayer(redlineNode)"></mat-checkbox>
                        <span>{{redlineNode.label}}</span>
                        <div class="legend-icon empty"></div>
                    </div>
                    <ul [class.node-invisible]="!redlineTreeControl.isExpanded(redlineNode)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let redlineNode; when: redlineHasOneClass">
                <li><!-- this is a layer node -->
                    <div class="mat-tree-node legend-node layer-node leaf-node">
                        <mat-checkbox [ngModel]="redlineNode.on"
                                      [disableRipple]="true"
                                      (ngModelChange)="toggleLayer(redlineNode)"></mat-checkbox>
                        <span>{{redlineNode.label}}</span>
                        <cs-redline-icon class="legend-icon"
                                         [redlineClassName]="redlineNode.classes[0].name"
                                         [redlineConfig]="redlineNode"
                                         *ngIf="redlineNode.classes.length === 1"></cs-redline-icon>
                    </div>
                </li>
            </mat-nested-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let redlineClassNode;">
                <li class="mat-tree-node legend-node leaf-node">
                    <div class="legend-label">{{redlineClassNode.label}}</div>
                    <cs-redline-icon class="legend-icon"
                                     [redlineClassName]="redlineClassNode.name"
                                     [redlineConfig]="redlineClassNode.parent"></cs-redline-icon>
                </li>
            </mat-nested-tree-node>
        </mat-tree>
    </ng-container>

    <!-- MAPSERVER -->
    <ng-container *ngIf="dataSource.data.length > 0">
        <h2 class="mat-h3" *ngIf="redlineDataSource.data.length > 0">Layers</h2>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" [trackBy]="trackByFn">

            <!--this is an expandable group node -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: isExpandableGroup">
                <li>
                    <div class="mat-tree-node legend-node group-node expandable-node">
                        <button mat-icon-button matTreeNodeToggle
                                (click)="toggleNode(node)"
                                [attr.aria-label]="'toggle ' + node.label">
                            <mat-icon class="mat-icon-rtl-mirror"
                                      [svgIcon]="treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'">
                            </mat-icon>
                        </button>
                        <mat-checkbox *ngIf="!node.disableToggle; else disableToggle"
                                      [ngModel]="node.status"
                                      [disableRipple]="true"
                                      (ngModelChange)="toggleGroup(node)"></mat-checkbox>
                        <mat-icon class="mat-icon-rtl-mirror folder-icon" svgIcon="folder"></mat-icon>
                        <span>{{node.label}}</span>
                    </div>
                    <ul [class.node-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>

            <!--this is a group node -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: isGroup">
                <li>
                    <div class="mat-tree-node legend-node group-node leaf-node">
                        <mat-checkbox *ngIf="!node.disableToggle; else disableToggle"
                                      [ngModel]="node.status"
                                      [disableRipple]="true"
                                      (ngModelChange)="toggleGroup(node)"></mat-checkbox>
                        <mat-icon class="mat-icon-rtl-mirror folder-icon" svgIcon="folder"></mat-icon>
                        <span>{{node.label}}</span>
                    </div>
                </li>
            </mat-nested-tree-node>

            <!-- this is an expandable layer node (has more than 1 class) -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: isExpandableLayer">
                <li>
                    <div class="mat-tree-node legend-node layer-node expandable-node">
                        <button mat-icon-button matTreeNodeToggle
                                (click)="toggleNode(node)"
                                [attr.aria-label]="'toggle ' + node.legendLabel">
                            <mat-icon class="mat-icon-rtl-mirror"
                                      [svgIcon]="treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'">
                            </mat-icon>
                        </button>
                        <mat-checkbox *ngIf="!node.disableToggle; else disableToggle"
                                      [ngModel]="node.status"
                                      [disableRipple]="true"
                                      (ngModelChange)="toggleLayer(node)"></mat-checkbox>
                        <span>{{node.label}}</span>
                    </div>
                    <ul [class.node-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>

            <!-- this is a layer node (has more than 1 class) that is explicitly not expandable -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: isNonExpandableLayer">
                <li>
                    <div class="mat-tree-node legend-node layer-node leaf-node">
                        <mat-checkbox *ngIf="!node.disableToggle; else disableToggle"
                                      [ngModel]="node.status"
                                      [disableRipple]="true"
                                      (ngModelChange)="toggleLayer(node)"></mat-checkbox>
                        <span>{{node.label}}</span>
                    </div>
                </li>
            </mat-nested-tree-node>

            <mat-nested-tree-node *matTreeNodeDef="let node; when: isLayer">
                <li><!-- this is a layer node -->
                    <div class="mat-tree-node legend-node layer-node leaf-node"
                         fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-checkbox *ngIf="!node.disableToggle; else disableToggle"
                                      [ngModel]="node.status"
                                      [disableRipple]="true"
                                      (ngModelChange)="toggleLayer(node)"></mat-checkbox>
                        <span>{{node.label}}</span>
                        <div *ngIf="node.classes.length === 1" class="legend-icon {{node.name}}_{{node.classes[0].index}}"></div>
                    </div>
                </li>
            </mat-nested-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node;">
                <li class="mat-tree-node legend-node leaf-node">
                    <div class="legend-label">{{node.name}}</div>
                    <div class="legend-icon {{node.layer}}_{{node.index}}"></div>
                </li>
            </mat-nested-tree-node>
        </mat-tree>
    </ng-container>
</div>


<ng-template #toggle let-node>
    <mat-checkbox [ngModel]="node.status"
                  [disableRipple]="true"
                  (ngModelChange)="toggleLayer(node)"></mat-checkbox>
</ng-template>

<ng-template #disableToggle let-node>
    <div class='no-checkbox'></div>
</ng-template>
