import { Injectable } from '@angular/core';
import { MapCommand } from '../map-command.class';
import { ofActionSuccessful } from '@ngxs/store';
import { CopyCoordinates } from '../../state/active-map.actions';
import { take } from 'rxjs/operators';

@Injectable()
export class CopyLatLonCommand extends MapCommand {

    invoke(): void {
        this.deps['interactions'].enableClickToCopyCoordinates();
        this.deps['actions$'].pipe(
            ofActionSuccessful(CopyCoordinates),
            take(1)
        ).subscribe(() => this.deactivate$.next());
    }
}
