import { NgModule } from '@angular/core';
import { MapContextMenuComponent } from './map-context-menu.component';
import { SharedModule } from '../../shared/shared.module';
import { MapContextMenuItemComponent } from './widget/map-context-menu-item.component';
import { ActiveToolModule } from '../toolbar/active-tool/active-tool.module';
import { MapContextSubMenuComponent } from './sub-menu/map-context-sub-menu.component';


@NgModule({
    declarations: [
        MapContextMenuComponent,
        MapContextMenuItemComponent,
        MapContextSubMenuComponent,
    ],
    exports: [
        MapContextMenuComponent,
    ],
    imports: [
        SharedModule,
        ActiveToolModule,
    ]
})
export class MapContextMenuModule {}
