import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordErrorMatcher } from '../../../../../../modules/shared/validators/password-error-matcher';
import { PasswordComplexity, PasswordRequirements } from '../../../../state/authState.model';
import { LoggerService } from '../../../../../../modules/shared/services/logger.service';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { ResetPasswordService } from '../../../../services/reset-password.service';
import { AuthState } from '../../../../state/authState.state';
import { filter, map, switchMapTo, take } from 'rxjs/operators';
import { PasswordComplexityValidator } from '../../password-complexity.validator';
import { UpdatePasswordComplexityUI } from '../../../../state/authState.actions';
import { BasePasswordFormComponent } from '../base-password-form.component';

@Component({
    selector: 'cs-change-password-form',
    templateUrl: './change-password-form.component.html',
    styleUrls: [ './change-password-form.component.scss' ]
})
export class ChangePasswordFormComponent
    extends BasePasswordFormComponent
    implements OnInit {


    requirements: PasswordRequirements;
    complexity: PasswordComplexity = { score: 0, feedback: { warning: '', suggestions: [ '' ] } };

    @Input() username = '';
    @Input() submitText = 'Reset Password';

    constructor(
        private logger: LoggerService,
        private fb: UntypedFormBuilder,
        private actions$: Actions,
        private store: Store,
        private resetService: ResetPasswordService
    ) {
        super();
        this.passwordForm = this.fb.group({
            password: [ '', Validators.required ],
            newPassword: [ '', Validators.required ],
            newPasswordConfirm: [ '', Validators.required ]
        });
    }

    ngOnInit(): void {
        this.store.select(AuthState.loaded).pipe(
            filter(l => l),
            take(1),
            switchMapTo(this.store.select(AuthState.methodConfig)),
            map(fn => fn('local')),
            take(1)
        ).subscribe((config) => {
            this.requirements = config?.passwordRequirements || { minStrength: 0, minLength: 6 };
            const complexityValidator = PasswordComplexityValidator
                .createValidator(this.resetService, this.store, this.requirements);

            this.newPassword.setValidators([
                Validators.required,
                Validators.minLength(this.requirements.minLength)
            ]);
            this.newPassword.setAsyncValidators(complexityValidator);
            this.newPasswordConfirm.setValidators(Validators.required);

            this.passwordForm.setValidators([
                this.matchValidator
            ]);

            // this.changePasswordForm.updateValueAndValidity();
        });


        this.actions$.pipe(
            ofActionSuccessful(UpdatePasswordComplexityUI)
        ).subscribe((action) => {
            this.complexity = action.complexity;
        });
    }
}
