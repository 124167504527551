import { Injectable } from '@angular/core';
import { QRCodeToStringOptions, toString } from 'qrcode';
import { Observable } from 'rxjs';
import { LoggerService } from './logger.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class QrCodeService {
    constructor(protected logger: LoggerService) {}

    generateQrCode$(value: string, width: number): Observable<string> {
        const opts: QRCodeToStringOptions = {
            type: 'svg',
            errorCorrectionLevel: 'Q',
            margin: 4,
            width,
        };

        return from(toString(value, opts)).pipe(
            map((qrString) => {
                const regex = /(width|height)="(\d+)"/g;
                return qrString.replace(regex, '$1="$2mm"');
            })
        );
    }
}
